import { createContext, useState } from "react";

import {
    FileNameModel,
    TitleModel,
    ImgElementsModel,
    WorkspaceModel,
} from "model/contextModel";

type FormDataContextType = {
    context: boolean;
};
export const FromDataContext = createContext<FormDataContextType>(
    {} as FormDataContextType
);
export const FromDataProvider = ({ children }: any) => {
    return (
        <FromDataContext.Provider value={{ context: true }}>
            <FileNameProvider>
                <TitleProvider>
                    <TopImgProvider>
                        <WorkspaceProvider>
                            <WorkspaceImgProvider>
                                {children}
                            </WorkspaceImgProvider>
                        </WorkspaceProvider>
                    </TopImgProvider>
                </TitleProvider>
            </FileNameProvider>
        </FromDataContext.Provider>
    );
};

type FileNameContextType = {
    fileNameList: FileNameModel;
    updateFileName: (data: FileNameModel) => void;
};
/** ドロップされたコンテ元画像のファイル名を管理 */
export const FileNameContext = createContext<FileNameContextType>(
    {} as FileNameContextType
);
/** ドロップされたコンテ元画像のファイル名を管理 */
const FileNameProvider = ({ children }: any) => {
    const [fileNameList, setFileName] = useState<FileNameModel>([]);
    /**
     * ドロップされたコンテ元画像のファイル名の配列を一時保存
     * @param data ドロップされたコンテ元画像のファイル名の配列
     */
    const updateFileName = (data: FileNameModel) => {
        // console.log("file name");
        setFileName(data);
    };
    return (
        <FileNameContext.Provider value={{ fileNameList, updateFileName }}>
            {children}
        </FileNameContext.Provider>
    );
};

type TitleContextType = {
    titleData: TitleModel | null;
    updateTitle: (data: TitleModel | null) => void;
};
/** 作品情報を管理 */
export const TitleContext = createContext<TitleContextType>(
    {} as TitleContextType
);
/** 作品情報を管理 */
const TitleProvider = ({ children }: any) => {
    const [titleData, setTitleData] = useState<TitleModel | null>(null);
    /**
     * 作品情報を一時保存
     * @param data title, episode, fps のデータ
     */
    const updateTitle = (data: TitleModel | null) => {
        // console.log("title");
        setTitleData(data);
    };
    return (
        <TitleContext.Provider value={{ titleData, updateTitle }}>
            {children}
        </TitleContext.Provider>
    );
};

type ImgElementsContextType = {
    imgElements: ImgElementsModel | null;
    updateImgElements: (data: ImgElementsModel | null) => void;
};
/** コンテ画像を管理 */
export const TopImgContext = createContext<ImgElementsContextType>(
    {} as ImgElementsContextType
);
/** コンテ画像を管理 */
const TopImgProvider = ({ children }: any) => {
    const [imgElements, setImgElements] = useState<ImgElementsModel | null>(
        null
    );
    /**
     * コンテ画像を一時保存
     * @param data base64をデコードしてセット済みの img elements
     */
    const updateImgElements = (data: ImgElementsModel | null) => {
        // console.log("img");
        setImgElements(data);
    };
    return (
        <TopImgContext.Provider
            value={{
                imgElements,
                updateImgElements,
            }}
        >
            {children}
        </TopImgContext.Provider>
    );
};
/** コンテをカットした画像を管理 */
export const WorkspaceImgContext = createContext<ImgElementsContextType>(
    {} as ImgElementsContextType
);
/** コンテをカットした画像を管理 */
const WorkspaceImgProvider = ({ children }: any) => {
    const [imgElements, setImgElements] = useState<ImgElementsModel | null>(
        null
    );
    /**
     * コンテをカットした画像を一時保存
     * @param data base64をデコードしてセット済みの img elements
     */
    const updateImgElements = (data: ImgElementsModel | null) => {
        // console.log("img");
        setImgElements(data);
    };
    return (
        <WorkspaceImgContext.Provider
            value={{
                imgElements,
                updateImgElements,
            }}
        >
            {children}
        </WorkspaceImgContext.Provider>
    );
};

type WorkspaceContextType = {
    workspaceData: WorkspaceModel | null;
    updateWorkspace: (data: WorkspaceModel | null) => void;
};
/** ワークスペースフォームの情報を管理 */
export const WorkspaceContext = createContext<WorkspaceContextType>(
    {} as WorkspaceContextType
);
/** ワークスペースフォームの情報を管理 */
const WorkspaceProvider = ({ children }: any) => {
    const [workspaceData, setWorkspaceData] = useState<WorkspaceModel | null>(
        null
    );
    /**
     * ワークスペースフォームの情報を一時保存
     * @param data inter, output_file_path のデータ
     */
    const updateWorkspace = (data: WorkspaceModel | null) => {
        // console.log("workspace");
        setWorkspaceData(data);
    };
    return (
        <WorkspaceContext.Provider value={{ workspaceData, updateWorkspace }}>
            {children}
        </WorkspaceContext.Provider>
    );
};
