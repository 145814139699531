import { useContext } from "react";
import { Helmet } from "react-helmet-async";
import { Link as RouterLink } from "react-router-dom";

import { Grid, Typography, Button } from "@mui/material";

export default function NotFound() {
    return (
        <>
            <Helmet>
                {/* ブラウザのタブに表示されるテキスト */}
                <title>Livret - NotFound</title>
            </Helmet>
            <div style={{ margin: "auto" }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    textAlign="center"
                >
                    <Grid
                        item
                        xs={6}
                        minWidth={560}
                        py={4}
                        sx={{ backgroundColor: "aliceblue" }}
                    >
                        <Typography
                            variant="h2"
                            gutterBottom
                            m={0}
                            sx={{ color: "coral" }}
                        >
                            livret
                        </Typography>
                        <Grid container direction="row" justifyContent="center">
                            <Grid item xs={10}>
                                <h2>ページが見つかりませんでした</h2>
                                <Button
                                    variant="outlined"
                                    component={RouterLink}
                                    to="/"
                                >
                                    トップに戻る
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}
