import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Grid, Box, Typography, IconButton, Tooltip } from "@mui/material";
import { DownloadForOfflineTwoTone, ReplayRounded } from "@mui/icons-material";

import apiClient from "apiClient";
import { DownloadFailedAlert } from "parts/Alert";

type FormProps = {
    token: string | null;
};
export function Download({ token }: FormProps) {
    const navigate = useNavigate();
    useEffect(() => {
        // トークンなしは未認証としてログインページに遷移
        // console.log(token);
        if (token === null) {
            navigate("/login");
        }
        getZipFile();

        // リロード前にconfirmを出して確認
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            // メッセージの設定
            const message = "一時保存されてる情報がリセットされます。";
            event.returnValue = message;
            return message;
        };
        window.addEventListener("beforeunload", handleBeforeUnload);
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, []);

    const [isDownloadFailedAlert, setIsDownloadFailedAlert] = useState(false);
    const [url, setUrl] = useState<string | null>(null);

    /**
     * ダウンロード用のURLを取得
     */
    function getZipFile() {
        apiClient
            .get("/api/process/zip", {
                responseType: "arraybuffer",
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: "application/zip",
                },
            })
            .then((response) => {
                // console.log(response.data);
                const blob = new Blob([response.data], {
                    type: "application/zip",
                });
                setUrl(window.URL.createObjectURL(blob));
                setIsDownloadFailedAlert(false);
            })
            .catch((error) => {
                // console.log(error);
                setIsDownloadFailedAlert(true);
            });
    }

    return (
        <>
            <Grid container direction="row">
                <Grid item xs={8}>
                    <Typography variant="h4" py={0}>
                        お疲れ様でした。
                    </Typography>
                    <Box pt={3}>
                        <Typography variant="h6" py={1}>
                            ファイルが完成しました。
                        </Typography>
                        <Typography variant="h6" py={1}>
                            右のボタンからダウンロードできます。
                        </Typography>
                        <Typography variant="h6" py={1}>
                            10分以内にダウンロードしてください。
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={4} pr={6}>
                    {url ? (
                        <Tooltip title="ダウンロード">
                            <IconButton
                                aria-label="delete"
                                size="large"
                                href={url}
                                download="export.zip"
                            >
                                <DownloadForOfflineTwoTone
                                    fontSize="inherit"
                                    sx={{ fontSize: 160, color: "steelblue" }}
                                />
                            </IconButton>
                        </Tooltip>
                    ) : isDownloadFailedAlert ? (
                        <>
                            <DownloadFailedAlert
                                setAlert={isDownloadFailedAlert}
                            />
                            <Tooltip title="もう一度試す">
                                <IconButton
                                    aria-label="delete"
                                    size="large"
                                    onClick={getZipFile}
                                >
                                    <ReplayRounded
                                        fontSize="inherit"
                                        sx={{
                                            fontSize: 120,
                                            color: "steelblue",
                                        }}
                                    />
                                </IconButton>
                            </Tooltip>
                        </>
                    ) : (
                        <p>準備中</p>
                    )}
                </Grid>

                <Grid item xs={12}>
                    <Grid container justifyContent="center">
                        <Grid item xs={6} pt={3}></Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}
