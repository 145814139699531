import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import MyRoutes from "Routes";

import { Container } from "@mui/material";

import { FromDataProvider } from "Contexts/FormDataContext";
import { Manual } from "parts/Manual";

function App() {
    return (
        <>
            <FromDataProvider>
                <Container
                    maxWidth={false}
                    style={{
                        minHeight: "100vh",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        overflowX: "auto",
                    }}
                >
                    <Router>
                        <MyRoutes />
                        <Manual />
                    </Router>
                </Container>
            </FromDataProvider>
        </>
    );
}

export default App;
