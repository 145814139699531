import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from "@mui/material";
/**
 * @property resolve : Promiseのresolve
 * @example
 * const [dialogProps, setDialogProps] = useState<DialogProps | undefined>();
 * // ダイアログの結果がboolean型で分かる
 * const postFlag = await new Promise<boolean>((resolve) => {
 *         setPostDialogProps({
 *             resolve: resolve,
 *         });
 *     });
 * return (<>
 * {dialogProps && <ExampleDialog {...dialogProps} />}
 * </>);
 */
export type DialogProps = {
    resolve: (value: boolean) => void;
};
/**
 * ファイルフォームのPOST前確認ダイアログ
 * @param resolve ダイアログの表示と回答を感知
 * @see resolveの使い方はDialogPropsを参照
 */
export function FilePostDialog({ resolve }: DialogProps) {
    return (
        <DialogItem
            resolve={resolve}
            title="作品情報とコンテを送信"
            text="この内容でよろしいですか？"
            falseButton="入力を続ける"
            trueButton="OK"
        />
    );
}
/**
 * ファイルフォームのリセット前確認ダイアログ
 * @param resolve ダイアログの表示と回答を感知
 * @see resolveの使い方はDialogPropsを参照
 */
export function FileCancelDialog({ resolve }: DialogProps) {
    return (
        <DialogItem
            resolve={resolve}
            title="入力をリセット"
            text={`入力した作品情報と選択済みの画像はすべて失われます。\nリセットしてよろしいですか？`}
            falseButton="入力を続ける"
            trueButton="OK"
        />
    );
}
/**
 * ワークスペースフォームのPOST前確認ダイアログ
 * @param resolve ダイアログの表示と回答を感知
 * @see resolveの使い方はDialogPropsを参照
 */
export function WorkspacePostDialog({ resolve }: DialogProps) {
    return (
        <DialogItem
            resolve={resolve}
            title="ZIPファイルの作成"
            text={`この内容で作成してよろしいですか？`}
            falseButton="入力を続ける"
            trueButton="OK"
        />
    );
}

type DialogItemProps = {
    resolve: (value: boolean) => void;
    title: string;
    text: string;
    falseButton: string;
    trueButton: string;
};
/**
 * 確認用ダイアログ、OKボタンとキャンセルボタン付き
 * @param resolve ダイアログの表示と回答を感知
 * @param title ダイアログのタイトルテキスト
 * @param text ダイアログの本文テキスト 改行にも対応
 * @param falseButton ダイアログのキャンセルボタンのテキスト
 * @param trueButton ダイアログのOKボタンのテキスト
 * @example
 *      <DialogItem
 *          resolve={resolve}
 *          title="タイトル"
 *          text={`このテキストは\n改行可`}
 *          falseButton="cancel"
 *          trueButton="OK"
 *      />
 */
function DialogItem(props: DialogItemProps) {
    return (
        <Dialog
            open
            onClose={() => props.resolve(false)}
            fullWidth
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
            <DialogContent>
                <DialogContentText
                    id="alert-dialog-description"
                    sx={{ whiteSpace: "pre-line" }}
                >
                    {props.text}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={() => props.resolve(false)}>
                    {props.falseButton}
                </Button>
                <Button
                    variant="contained"
                    type="button"
                    autoFocus
                    onClick={() => props.resolve(true)}
                >
                    {props.trueButton}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
