import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate } from "react-router-dom";

import { Grid, Typography, Button } from "@mui/material";

import { Download } from "parts/ExportButton";

export default function Expost() {
    const location = useLocation();
    const token = location.state ? location.state.token : null;
    const navigate = useNavigate();

    return (
        <>
            <Helmet>
                {/* ブラウザのタブに表示されるテキスト */}
                <title>Livret - ダウンロード</title>
            </Helmet>
            <div style={{ margin: "auto" }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    textAlign="center"
                >
                    <Grid
                        item
                        xs={6}
                        pt={3}
                        px={5}
                        minWidth={760}
                        sx={{ backgroundColor: "aliceblue" }}
                    >
                        <Typography
                            variant="h2"
                            gutterBottom
                            sx={{ color: "coral" }}
                        >
                            livret
                        </Typography>
                        <Grid item xs={12} pt={5}>
                            <Download token={token} />
                        </Grid>
                        <Grid item xs={12} py={5}>
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    navigate("/workspace", {
                                        state: { data: null, token },
                                    });
                                }}
                                sx={{
                                    mx: 1.5,
                                    px: 1,
                                    width: 140,
                                }}
                                style={{
                                    color: "steelblue",
                                    backgroundColor: "#e2e9f0",
                                }}
                            >
                                編集の続きに戻る
                            </Button>
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    navigate("/", {
                                        state: { token },
                                    });
                                }}
                                sx={{
                                    mx: 1.5,
                                    width: 120,
                                }}
                                style={{
                                    color: "#e2e9f0",
                                    backgroundColor: "steelblue",
                                }}
                            >
                                トップに戻る
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}
