import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";

import { Grid, Typography } from "@mui/material";

import { FileForm } from "parts/FileForm";

export default function Top() {
    const location = useLocation();
    /**
     * ユーザー認証用　リクエストのヘッダに追加
     * @example
     * apiClient
     *     .get("/api/path", {
     *         headers: {
     *             Authorization: `Bearer ${token}`,
     *         },
     *     });
     */
    const token = location.state ? location.state.token : null;

    return (
        <>
            <Helmet>
                {/* ブラウザのタブに表示されるテキスト */}
                <title>Livret - 登録</title>
            </Helmet>
            <div style={{ margin: "auto" }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    mb={8}
                >
                    <Grid item xs={12}>
                        <Typography
                            variant="h2"
                            gutterBottom
                            textAlign="center"
                            m={0}
                            sx={{ color: "coral" }}
                        >
                            livret
                        </Typography>
                    </Grid>
                    <FileForm token={token} />
                </Grid>
            </div>
        </>
    );
}
