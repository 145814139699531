import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";

import { Grid, Typography } from "@mui/material";

import { WorkspaceForm } from "parts/WorkspaceForm";

export default function Workspace() {
    const location = useLocation();
    const data = location.state ? location.state.data : null;
    const token = location.state ? location.state.token : null;
    // console.log(token);

    return (
        <>
            <Helmet>
                {/* ブラウザのタブに表示されるテキスト */}
                <title>Livret - 設定</title>
            </Helmet>
            <div style={{ margin: "auto" }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    textAlign="center"
                >
                    <Grid item xs={12}>
                        <Typography
                            variant="h2"
                            gutterBottom
                            mb={0}
                            sx={{ color: "coral" }}
                        >
                            livret
                        </Typography>
                    </Grid>
                    <WorkspaceForm token={token} data={data} />
                </Grid>
            </div>
        </>
    );
}
