import { Helmet } from "react-helmet-async";

import { LoginForm } from "parts/LoginForm";

export default function Login() {
    return (
        <>
            <Helmet>
                {/* ブラウザのタブに表示されるテキスト */}
                <title>Livret - ログイン</title>
            </Helmet>
            <div style={{ margin: "auto" }}>
                <LoginForm />
            </div>
        </>
    );
}
