import { CSSProperties, useContext, ReactNode } from "react";

import {
    UseFormRegister,
    UseFieldArrayRemove,
    FieldArrayWithId,
} from "react-hook-form";

import {
    IconButton,
    Divider,
    Tooltip,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Typography,
    FormControlLabel,
    Switch,
    ListSubheader,
    ListItemText,
    Grid,
    ListItem,
    TextField,
    SxProps,
    Theme,
} from "@mui/material";
import {
    MenuRounded,
    HighlightOffTwoTone,
    AddCircleTwoTone,
} from "@mui/icons-material";

import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { ResponseModel, WorkspaceFormModel, CutObj } from "model/formModel";
import { ImgElementsModel } from "model/contextModel";
import { TitleContext, WorkspaceContext } from "Contexts/FormDataContext";

type InfoTableProps = {
    responseData: ResponseModel;
};
/**
 * title, episode, fps の表示欄
 */
export function InfoTable({ responseData }: InfoTableProps) {
    const { titleData } = useContext(TitleContext);
    const { workspaceData } = useContext(WorkspaceContext);
    // response のデータがあるときはそっちを優先
    let data;
    if (responseData) {
        data = responseData;
    } else if (titleData) {
        data = titleData;
    } else if (workspaceData) {
        data = workspaceData.inter;
    }
    return (
        <Table size="small">
            <TableBody>
                <InfoRow
                    keyText={process.env.REACT_APP_TITLE}
                    keyVariant="body2"
                    valueText={data ? data.title : "取得に失敗しました"}
                    valueVariant="h5"
                />
                <InfoRow
                    keyText={process.env.REACT_APP_PART}
                    keyVariant="caption"
                    valueText={data ? data.episode : ""}
                    valueVariant="subtitle1"
                />
                <InfoRow
                    keyText={process.env.REACT_APP_FPS}
                    keyVariant="caption"
                    valueText={data ? data.fps : ""}
                    valueVariant="subtitle1"
                />
            </TableBody>
        </Table>
    );
}

type InfoRowProps = {
    keyText: string | undefined;
    keyVariant: "caption" | "body2";
    valueText: string | number;
    valueVariant: "subtitle1" | "h5";
};
function InfoRow({ ...props }: InfoRowProps) {
    return (
        <TableRow>
            <TableCell align="right" width={100} sx={{ pb: 0 }}>
                <Typography variant={props.keyVariant}>
                    {props.keyText}
                </Typography>
            </TableCell>
            <TableCell align="left" sx={{ pb: 0 }}>
                <Typography variant={props.valueVariant}>
                    {props.valueText}
                </Typography>
            </TableCell>
        </TableRow>
    );
}

type ControlButtonsProps = {
    editMarkersChecked: boolean;
    setEditMarkersChecked: React.Dispatch<React.SetStateAction<boolean>>;
    addMarker: () => void;
    switchText: string;
    addBtnText: string;
};
/**
 * リスト表示のためのボタン ー 表示切替トグル、マーカー追加ボタン
 */
export function ControlButtons({
    editMarkersChecked,
    setEditMarkersChecked,
    addMarker,
    switchText,
    addBtnText,
}: ControlButtonsProps) {
    function editCutMarkers(event: React.ChangeEvent<HTMLInputElement>) {
        setEditMarkersChecked(event.target.checked);
    }
    return (
        <>
            {switchText === "カット位置を編集" && (
                <FormControlLabel
                    control={
                        <Switch
                            checked={editMarkersChecked}
                            onChange={editCutMarkers}
                            inputProps={{ "aria-label": "controlled" }}
                        />
                    }
                    style={{ color: "#494949" }}
                    label={switchText}
                />
            )}
            <IconButton
                size="small"
                onClick={addMarker}
                color="primary"
                disableRipple
            >
                <AddCircleTwoTone />
                <Typography variant="body1" p={1} style={{ color: "#494949" }}>
                    {addBtnText}
                </Typography>
            </IconButton>
        </>
    );
}
type MyListItemProps = {
    children: ReactNode;
    field:
        | FieldArrayWithId<WorkspaceFormModel, "inter.timeline", "id">
        | FieldArrayWithId<WorkspaceFormModel, "inter.sceneImg", "id">;
    remove: UseFieldArrayRemove;
    inActive: (id: number) => void;
    index: number;
    editMarkersChecked: boolean;
    markerNumber: number | null;
    isMarker: boolean;
    markerText: string;
};
/**
 * ドラッグ移動可能なリストのフレーム
 */
export function MyListItem({ ...props }: MyListItemProps) {
    // ドラッグ移動のための設定
    const id = props.field.id;
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({
        id,
    });
    const style: CSSProperties = {
        transform: CSS.Transform.toString(transform),
        transition,
        position: "relative",
        // ドラッグ中はz-indexプロパティでコンポーネントを浮かせる
        zIndex: isDragging ? 10 : 0,
        display: "flex",
        alignItems: "center",
        gap: "0 24px",
    };

    /**
     * Markerの削除ボタンが押されたときの処理
     * @param id fieldのID
     */
    function removeMarker(id: number) {
        props.remove(id);
    }

    // 無効時はグレーアウト
    const BGCStyle: SxProps<Theme> = props.field.active
        ? {}
        : { backgroundColor: "#d4d8db" };

    return (
        <>
            {props.isMarker ? (
                // マーカー
                <>
                    <Divider variant="middle" />
                    <ListSubheader
                        sx={{
                            backgroundColor: "#e2e9f0",
                        }}
                        ref={setNodeRef}
                        // style={style}
                        {...attributes}
                        tabIndex={-1}
                    >
                        <ListItemText
                            sx={{
                                textAlign: "left",
                            }}
                        >
                            <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignContent="center"
                                height={50}
                                tabIndex={-1}
                            >
                                {props.editMarkersChecked &&
                                    props.markerNumber !== 1 && (
                                        // １番は動かせないように
                                        <DragIcon
                                            listeners={listeners}
                                            fontSize="medium"
                                        />
                                    )}
                                <Grid item xs={11}>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="space-between"
                                    >
                                        <Grid item xs={4} sx={{ fontSize: 20 }}>
                                            {props.markerText}
                                            <span
                                                style={{
                                                    fontWeight: "bolder",
                                                    fontSize: 28,
                                                    paddingLeft: 6,
                                                }}
                                            >
                                                {props.markerNumber === -1
                                                    ? "NEW"
                                                    : props.markerNumber}
                                            </span>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={1}
                                            sx={{
                                                textAlign: "center",
                                                my: "auto",
                                            }}
                                        >
                                            {props.editMarkersChecked &&
                                                props.markerNumber !== 1 && (
                                                    // １番は消せないように
                                                    <DeleteButton
                                                        index={props.index}
                                                        onClick={removeMarker}
                                                        isActive={
                                                            props.field.active
                                                        }
                                                        tipText="カット番号の削除"
                                                    />
                                                )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </ListItemText>
                    </ListSubheader>
                </>
            ) : (
                // 入力欄
                <>
                    <Divider variant="middle" />
                    <ListItem
                        ref={setNodeRef}
                        style={style}
                        {...attributes}
                        tabIndex={-1}
                        sx={BGCStyle}
                    >
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-around"
                        >
                            <Grid
                                item
                                xs={1}
                                sx={{ textAlign: "center", my: "auto" }}
                            >
                                {!props.editMarkersChecked &&
                                    props.field.active && (
                                        <DragIcon
                                            listeners={listeners}
                                            fontSize="large"
                                        />
                                    )}
                            </Grid>
                            {props.children}
                            <Grid item xs={1} sx={{ textAlign: "right" }}>
                                {!props.editMarkersChecked && (
                                    <DeleteButton
                                        index={props.index}
                                        onClick={props.inActive}
                                        isActive={props.field.active}
                                        tipText="削除"
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </ListItem>
                </>
            )}
        </>
    );
}
type CutListItemProps = {
    field: FieldArrayWithId<WorkspaceFormModel, "inter.timeline", "id">;
    index: number;
    imgList: ImgElementsModel | null;
    register: UseFormRegister<WorkspaceFormModel>;
};
/**
 * timelineリストのコマ表示のアイテム
 */
export function CutListItem({
    field,
    index,
    imgList,
    register,
}: CutListItemProps) {
    // 無効時のは不透明度を下げる
    const OpacityStyle: SxProps<Theme> = field.active
        ? { textAlign: "center", my: "auto" }
        : { textAlign: "center", my: "auto", opacity: 0.4 };
    return (
        <>
            <Grid item xs={6} sx={OpacityStyle}>
                {imgList ? (
                    imgList[field.imgpic]
                ) : (
                    <p>画像を読み込めませんでした。</p>
                )}
            </Grid>
            <Grid item xs={2} sx={OpacityStyle}>
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    spacing={2}
                >
                    <Grid item xs={12}>
                        {imgList ? (
                            imgList[field.imgcuttime]
                        ) : (
                            <p>画像を読み込めませんでした。</p>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            disabled={!field.active} //無効時は入力不可に
                            type="number"
                            inputProps={{
                                min: 0,
                            }}
                            label={process.env.REACT_APP_FRAMES}
                            variant="filled"
                            sx={{
                                width: 80,
                            }}
                            {...register(`inter.timeline.${index}.frames`)}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}
type SceneListItemProps = {
    data: CutObj;
    imgList: ImgElementsModel | null;
};
/**
 * scene編集リストのカット表示アイテム
 */
export function SceneListItem({ data, imgList }: SceneListItemProps) {
    return (
        <>
            <Grid item xs={1} textAlign="center">
                <Typography
                    variant="body2"
                    sx={{ fontSize: 20, color: "slategray" }}
                >
                    cut
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        fontSize: 25,
                        fontWeight: "bolder",
                        color: "#56626f",
                    }}
                >
                    {data.cut}
                </Typography>
            </Grid>
            {data.begin === "SubHeader" ? (
                <Grid
                    item
                    xs={8}
                    pl={1}
                    mx={1}
                    textAlign="center"
                    alignContent="center"
                >
                    <Typography
                        variant="body2"
                        my="auto"
                        sx={{ fontSize: 14, color: "slategray" }}
                    >
                        有効なコマがありません
                    </Typography>
                </Grid>
            ) : (
                <>
                    <Grid item xs={4} pl={1}>
                        {imgList ? (
                            imgList[data.begin]
                        ) : (
                            <p>画像を読み込めませんでした。</p>
                        )}
                    </Grid>
                    <Grid item xs={4}>
                        {imgList ? (
                            imgList[data.end]
                        ) : (
                            <p>画像を読み込めませんでした。</p>
                        )}
                    </Grid>
                </>
            )}
        </>
    );
}

type DragIconProps = {
    listeners: SyntheticListenerMap | undefined;
    fontSize: "medium" | "large";
};
/**
 * @param listeners ドラッグ移動のリスナ
 * @param fontSize "medium" | "large"
 */
function DragIcon({ listeners, fontSize }: DragIconProps) {
    return (
        <Tooltip title="ドラッグで移動">
            <MenuRounded
                {...listeners}
                color="disabled"
                fontSize={fontSize}
                sx={{ my: "auto" }}
            />
        </Tooltip>
    );
}
type DeleteButtonProps = {
    index: number;
    onClick: (id: number) => void;
    isActive: boolean;
    tipText: string;
};
/**
 * 有効時は削除ボタン、無効時は追加ボタン
 * @param index リストのインデックス
 * @param onClick ボタンが押されたときの処理関数
 * @param isActive true なら有効 false なら無効
 * @param tipText ツールチップのテキスト
 */
function DeleteButton({
    index,
    onClick,
    isActive,
    tipText,
}: DeleteButtonProps) {
    return (
        <Tooltip title={isActive ? tipText : "追加"}>
            <IconButton
                size="small"
                onClick={() => onClick(index)}
                tabIndex={-1}
                color={isActive ? "error" : "primary"}
            >
                {isActive ? <HighlightOffTwoTone /> : <AddCircleTwoTone />}
            </IconButton>
        </Tooltip>
    );
}
