import {
    useCallback,
    useEffect,
    useState,
    useMemo,
    useContext,
    SetStateAction,
} from "react";
import { useNavigate } from "react-router-dom";
import { UseFormRegister, useForm } from "react-hook-form";
import { useDropzone } from "react-dropzone";

import {
    Grid,
    Box,
    Typography,
    Button,
    TextField,
    ListItem,
    ListItemText,
    ImageList,
    ImageListItem,
    ImageListItemBar,
    IconButton,
    Tooltip,
    Skeleton,
} from "@mui/material";

import apiClient from "apiClient";
import { FileFormModel, FileName } from "model/formModel";
import {
    FileNameContext,
    TitleContext,
    TopImgContext,
    WorkspaceContext,
    WorkspaceImgContext,
} from "Contexts/FormDataContext";
import {
    ImgUploadFailedAlert,
    PostFailedAlert,
    UnAddedAlert,
} from "parts/Alert";
import { FilePostDialog, DialogProps, FileCancelDialog } from "parts/Dialog";
import {
    FilePostProgressDialog,
    ProgressDialogProps,
} from "parts/ProgressDialog";
import { HighlightOffTwoTone } from "@mui/icons-material";
import { ImgElementsModel } from "model/contextModel";

type FileFormProps = {
    token: string | null;
};
/**
 * 作品情報と絵コンテの元画像を登録するフォーム
 * @param token ユーザー認証用　リクエストのヘッダに追加
 */
export function FileForm({ token }: FileFormProps) {
    const navigate = useNavigate();
    useEffect(() => {
        // トークンなしは未認証としてログインページに遷移
        // console.log(token);
        if (token === null) {
            navigate("/login");
        }

        // リロード前にcnofirmを出して確認
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            const message = "一時保存されてる情報がリセットされます。";
            event.returnValue = message;
            return message;
        };
        window.addEventListener("beforeunload", handleBeforeUnload);
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, []);

    const { fileNameList, updateFileName } = useContext(FileNameContext);
    const { titleData, updateTitle } = useContext(TitleContext);
    const { updateWorkspace } = useContext(WorkspaceContext);
    const { updateImgElements } = useContext(WorkspaceImgContext);

    const defaultValues = {
        title: titleData?.title,
        episode: titleData?.episode,
        fps: titleData?.fps,
    };

    const [postDialogProps, setPostDialogProps] = useState<
        DialogProps | undefined
    >();
    const [cancelDialogProps, setCancelDialogProps] = useState<
        DialogProps | undefined
    >();
    const [openProgressDialog, setOpenProgressDialog] = useState<
        ProgressDialogProps | undefined
    >({
        open: false,
    });
    const [isPostFailedAlert, setIsPostFailedAlert] = useState(false);
    const [isUnAddedAlert, setIsUnAddedAlert] = useState(false);
    const [isImgUploadFailedAlert, setIsImgUploadFailedAlert] = useState(false);

    // ファイルフォームの設定
    const {
        register,
        handleSubmit,
        reset,
        // watch,
        // setValue,
        // formState: { errors }, // エラー情報 (メッセージなど) を含む`state`
    } = useForm<FileFormModel>({
        mode: "onChange", // バリデーション判定タイミング (`onChange`は入力値の変化毎)
        defaultValues,
        // resolver: yupResolver(schema),
    });

    // ファイルフォーム送信時の処理
    const onSubmit = async (data: FileFormModel) => {
        // console.log(data);

        if (fileNameList.length < 1) {
            setIsUnAddedAlert(true);
            return;
        }
        // 確認ダイアログ表示
        const postFlag = await new Promise<boolean>((resolve) => {
            setPostDialogProps({
                resolve: resolve,
            });
        });
        // 確認ダイアログ閉じ
        setPostDialogProps(undefined);
        if (!postFlag) {
            return;
        }
        setOpenProgressDialog({ open: true });
        // データ送信
        apiClient
            .post("/api/process/img", data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                // console.log(response);
                updateTitle(data);
                // ワークスペースフォームのデフォルト値設定のためデータ渡す
                navigate("/workspace", {
                    state: { data: response.data, token },
                });
            })
            .catch((error) => {
                console.log(error);
                setIsUnAddedAlert(false);
                setIsPostFailedAlert(true);
                setOpenProgressDialog({ open: false });
            });
    };

    // ファイルフォームリセット時の処理
    const onReset = async () => {
        // console.log("reset");
        // 確認ダイアログ表示
        const cancelFlag = await new Promise<boolean>((resolve) => {
            setCancelDialogProps({
                resolve: resolve,
            });
        });
        // 確認ダイアログ閉じ
        setCancelDialogProps(undefined);
        if (cancelFlag) {
            // console.log("cancel");
            const fps = Number(process.env.REACT_APP_FPS_DEFAULT_VALUE);
            apiClient
                .delete("/api/img", {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then((response) => {
                    // console.log(response);
                    // 一時保存データのリセット
                    updateTitle(null);
                    updateFileName([]);
                    updateWorkspace(null);
                    updateImgElements(null);
                    // 入力欄のリセット
                    reset({
                        title: "",
                        episode: "",
                        fps,
                    });
                    setIsPostFailedAlert(false);
                    setIsUnAddedAlert(false);
                    setIsImgUploadFailedAlert(false);
                })
                .catch((error) => {
                    // console.log(error);
                    return;
                });
        }
    };

    return (
        <Grid
            item
            xs={8}
            minWidth={980}
            p={4}
            sx={{ backgroundColor: "aliceblue" }}
        >
            <Grid container justifyContent="center">
                <Grid item xs={12}>
                    <Grid container justifyContent="center">
                        <Grid item xs={4}>
                            <PostFailedAlert setAlert={isPostFailedAlert} />
                            <UnAddedAlert setAlert={isUnAddedAlert} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item py={4} xs={5}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            rowSpacing={4}
                        >
                            <Grid item>
                                <Typography>作品情報を入力</Typography>
                            </Grid>
                            <InputItems register={register} />
                            <Grid
                                container
                                direction="row"
                                alignContent="center"
                                justifyContent="center"
                                spacing={3}
                                mt={2}
                            >
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        sx={{ width: 120 }}
                                        style={{
                                            backgroundColor: "firebrick",
                                            color: "snow",
                                        }}
                                        onClick={onReset}
                                    >
                                        リセット
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            sx={{ width: 120 }}
                                            type="submit"
                                        >
                                            確定
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>

                <Grid item pt={3} px={5} minWidth={300} xs={7}>
                    <ImgUploadForm
                        token={token}
                        setIsUnAddedAlert={setIsUnAddedAlert}
                        isImgUploadFailedAlert={isImgUploadFailedAlert}
                        setIsImgUploadFailedAlert={setIsImgUploadFailedAlert}
                    />
                </Grid>
            </Grid>
            {postDialogProps && <FilePostDialog {...postDialogProps} />}
            {cancelDialogProps && <FileCancelDialog {...cancelDialogProps} />}
            {openProgressDialog && (
                <FilePostProgressDialog {...openProgressDialog} />
            )}
        </Grid>
    );
}

type InputItemsProps = {
    register: UseFormRegister<FileFormModel>;
};
/**
 * title, part, fps の入力欄
 */
function InputItems({ register }: InputItemsProps) {
    return (
        <>
            <Grid item>
                <TextField
                    required
                    label={process.env.REACT_APP_TITLE}
                    type="search"
                    variant="outlined"
                    {...register("title")}
                />
            </Grid>
            <Grid item>
                <TextField
                    required
                    label={process.env.REACT_APP_PART}
                    type="search"
                    variant="outlined"
                    {...register("episode")}
                />
            </Grid>
            <Grid item>
                <TextField
                    required
                    label={process.env.REACT_APP_FPS}
                    type="number"
                    inputProps={{ min: "1" }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    defaultValue={process.env.REACT_APP_FPS_DEFAULT_VALUE}
                    {...register("fps")}
                />
            </Grid>
        </>
    );
}

/**
 * 絵コンテ元画像を追加するフォーム
 * @param token ユーザー認証用　リクエストのヘッダに追加
 */
type ImgUploadFormProps = {
    token: string | null;
    setIsUnAddedAlert: React.Dispatch<SetStateAction<boolean>>;
    isImgUploadFailedAlert: boolean;
    setIsImgUploadFailedAlert: React.Dispatch<SetStateAction<boolean>>;
};
function ImgUploadForm({
    token,
    setIsUnAddedAlert,
    isImgUploadFailedAlert,
    setIsImgUploadFailedAlert,
}: ImgUploadFormProps) {
    const { fileNameList, updateFileName } = useContext(FileNameContext);
    const { imgElements, updateImgElements } = useContext(TopImgContext);

    const [isLoading, setIsLoading] = useState(false);

    const imgList: ImgElementsModel = {};

    // 画像がドロップされたときの処理
    const onDrop = useCallback((files: File[]) => {
        if (files.length > 0) {
            setIsLoading(true);
            apiClient
                .post("/api/img", files, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((response) => {
                    // console.log(response);
                    const data: Array<FileName> = response.data;
                    // 表示用にファイル名のみの配列を作成
                    const fileNames: Array<string> = [];
                    data.forEach((d) => {
                        fileNames.push(d.file_name);
                        imgList[d.file_name] = (
                            <img
                                src={`data:image/png;base64,${d.img}`}
                                alt={d.file_name}
                                width={40}
                            />
                        );
                    });
                    updateFileName(fileNames);
                    updateImgElements(imgList);
                    setIsImgUploadFailedAlert(false);
                    setIsUnAddedAlert(false);
                    setIsLoading(false);
                })
                .catch((error) => {
                    // console.log(error);
                    setIsImgUploadFailedAlert(true);
                    setIsLoading(false);
                });
        }
    }, []);

    // ドラッグアンドドロップの設定
    const {
        getRootProps,
        getInputProps,
        //  isDragActive
    } = useDropzone({
        onDrop,
        accept: {
            // 受け入れる拡張子を限定
            "image/png": [".png", ".jpg", ".jpeg"],
        },
    });

    // 追加されたファイル名のリストを作成
    const NamesList = useMemo(() => {
        let list = [];
        if (fileNameList) {
            list = fileNameList.map((d, i) => {
                return (
                    <Tooltip key={`${i}`} title={d} followCursor>
                        <ImageListItem sx={{ width: 110, py: 0 }}>
                            <ImageListItemBar
                                position="top"
                                sx={{ backgroundColor: "#ffffff00" }}
                                actionIcon={
                                    <IconButton
                                        onClick={() => deleteImg(d)}
                                        sx={{ color: "lightcoral" }}
                                    >
                                        <HighlightOffTwoTone />
                                    </IconButton>
                                }
                            />
                            {imgElements && imgElements[d]}
                        </ImageListItem>
                    </Tooltip>
                );
            });
        } else {
            list.push(
                <ListItem key={`x`}>
                    <ListItemText primary={"未選択"} />
                </ListItem>
            );
        }
        return (
            <ImageList
                cols={4}
                sx={{
                    position: "relative",
                    overflow: "auto",
                    maxHeight: 280,
                    "& ul": { padding: 0 },
                    mt: 1,
                }}
            >
                {list}
            </ImageList>
        );
    }, [fileNameList]); // fileNameListに変更があった場合のみ実行

    const deleteImg = (fileName: string) => {
        // console.log("delete");
        apiClient
            .delete("/api/img", {
                data: { file_name: fileName },
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((response) => {
                // console.log(response);
                const data: Array<FileName> = response.data;
                // 表示用にファイル名のみの配列を作成
                const fileNames: Array<string> = [];
                data.forEach((d) => {
                    fileNames.push(d.file_name);
                });
                updateFileName(fileNames);
                setIsImgUploadFailedAlert(false);
            })
            .catch((error) => {
                // console.log(error);
                setIsImgUploadFailedAlert(true);
            });
    };

    return (
        <Grid container direction="column" justifyContent="center">
            <form>
                <Grid
                    item
                    sx={{
                        border: 1,
                        borderStyle: "dashed",
                        borderColor: "darkslategray",
                    }}
                    xs={12}
                >
                    <div {...getRootProps()}>
                        <input required {...getInputProps()} />
                        <Box
                            justifyContent="center"
                            alignItems="center"
                            display="flex"
                            height={120}
                        >
                            <Typography
                                p={2}
                                variant="body2"
                                textAlign="center"
                            >
                                コンテをここにドラッグアンドドロップするか
                                <br />
                                クリックしてファイルを選択してください
                            </Typography>
                        </Box>
                    </div>
                </Grid>
            </form>

            <Grid item mt={3}>
                {isLoading ? (
                    <ImgSkeleton />
                ) : isImgUploadFailedAlert ? (
                    <ImgUploadFailedAlert setAlert={isImgUploadFailedAlert} />
                ) : (
                    NamesList
                )}
            </Grid>
        </Grid>
    );
}

function ImgSkeleton() {
    return (
        <ImageList
            cols={4}
            sx={{
                position: "relative",
                overflow: "auto",
                maxHeight: 280,
                "& ul": { padding: 0 },
                mt: 1,
            }}
        >
            <Skeleton variant="rectangular" width={110} height={150} />
            <Skeleton variant="rectangular" width={110} height={150} />
            <Skeleton variant="rectangular" width={110} height={150} />
            <Skeleton variant="rectangular" width={110} height={150} />
        </ImageList>
    );
}
