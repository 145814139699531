import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
    useForm,
    useFieldArray,
    UseFormSetValue,
    UseFormRegister,
    UseFieldArrayMove,
    UseFieldArrayRemove,
    FieldArrayWithId,
    UseFieldArrayPrepend,
    UseFieldArrayAppend,
    UseFieldArrayUpdate,
} from "react-hook-form";

import { SortableContext } from "@dnd-kit/sortable";
import { DndContext, DragEndEvent } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";

import {
    Grid,
    Button,
    TextField,
    List,
    Divider,
    Box,
    Stepper,
    Step,
    StepLabel,
    Typography,
} from "@mui/material";

import apiClient from "apiClient";
import {
    ResponseModel,
    WorkspaceFormModel,
    Timeline,
    ResponseTimeline,
    CutObj,
} from "model/formModel";
import { ImgElementsModel } from "model/contextModel";
import {
    WorkspaceImgContext,
    WorkspaceContext,
} from "Contexts/FormDataContext";
import {
    InfoTable,
    ControlButtons,
    MyListItem,
    CutListItem,
    SceneListItem,
} from "parts/WorkspaceItem";
import {
    PostFailedAlert,
    AddCutMarkerAlert,
    AddSceneMarkerAlert,
} from "parts/Alert";
import { WorkspacePostDialog, DialogProps } from "parts/Dialog";
import {
    WorkspacePostProgressDialog,
    ProgressDialogProps,
} from "parts/ProgressDialog";

type WorkspaceFormProps = {
    token: string | null;
    data: ResponseModel;
};

/**
 * カット尺などを登録するフォーム
 * @param token ユーザー認証用　リクエストのヘッダに追加
 * @param data フォームのデフォルト値にセットするデータ　カットされたコンテ画像も含む
 */
export function WorkspaceForm({ token, data }: WorkspaceFormProps) {
    const navigate = useNavigate();
    useEffect(() => {
        // トークンなしは未認証としてログインページに遷移
        // console.log(token);
        if (token === null) {
            navigate("/login");
        }
        // リロード前にconfirmを出して確認
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            // メッセージの設定
            const message = "一時保存されてる情報がリセットされます。";
            event.returnValue = message;
            return message;
        };
        window.addEventListener("beforeunload", handleBeforeUnload);
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, []);

    //　一時保存中のデータを取得
    const { workspaceData, updateWorkspace } = useContext(WorkspaceContext);
    const { updateImgElements } = useContext(WorkspaceImgContext);
    useEffect(() => {
        if (data === null && workspaceData === null) {
            // リロード等で一時保存のデータも消えていた場合トップページへ遷移
            navigate("/", {
                state: { token },
            });
        }
    }, [workspaceData]);

    const [postDialogProps, setPostDialogProps] = useState<
        DialogProps | undefined
    >();
    const [openProgressDialog, setOpenProgressDialog] = useState<
        ProgressDialogProps | undefined
    >({
        open: false,
    });
    const [isPostFailedAlert, setIsPostFailedAlert] = useState(false);

    /**
     * カットされた画像の連想配列
     * @property key : ファイル名
     * @property value : 画像のセットされたimg element
     * @example
     * {
     *      "sample.png" :
     *      <img
     *          // base64を画像にデコードしてセット
     *          src={`data:image/png;base64,${base64}`}
     *          alt="sample.png"
     *      />
     * }
     */
    const base64List: ImgElementsModel = {};
    const timelineList: Array<Timeline> = [];
    const cuts: Array<CutObj> = [];
    let timeline: Array<ResponseTimeline | Timeline> | undefined =
        workspaceData?.inter.timeline;
    let defaultValues;
    if (data !== null) {
        data.timeline.forEach((d) => {
            base64List[d.imgpic] = (
                <img
                    src={`data:image/png;base64,${d.imgpic_base64}`}
                    alt={d.imgpic}
                    width={200}
                />
            );
            base64List[d.imgcuttime] = (
                <img
                    src={`data:image/png;base64,${d.imgcuttime_base64}`}
                    alt={d.imgcuttime}
                    width={80}
                />
            );
            timeline = data.timeline;
            defaultValues = {
                inter: {
                    title: data.title,
                    episode: data.episode,
                    fps: data.fps,
                    framesize: data.framesize,
                    frame_height: data.frame_height,
                    frame_width: data.frame_width,
                    src_height: data.src_height,
                    src_width: data.src_width,
                    timeline: timelineList,
                    noClapper: true,
                    scenes: [],
                    sceneImg: cuts,
                },
            };
            return;
        });
    } else {
        defaultValues = {
            inter: {
                title: workspaceData?.inter.title,
                episode: workspaceData?.inter.episode,
                fps: workspaceData?.inter.fps,
                framesize: workspaceData?.inter.framesize,
                frame_height: workspaceData?.inter.frame_height,
                frame_width: workspaceData?.inter.frame_width,
                src_height: workspaceData?.inter.src_height,
                src_width: workspaceData?.inter.src_width,
                timeline: timelineList,
                initialCutNumber: workspaceData?.inter.initialCutNumber,
                noClapper: true,
                scenes: [],
                sceneImg: workspaceData?.inter.sceneImg,
            },
            output_file_path: workspaceData?.output_file_path,
        };
    }

    const cutBegins: Array<string> = [];
    const cutEnds: Array<string> = [];
    if (timeline !== undefined) {
        timeline.forEach((d, i) => {
            let cut = d.cut;
            // カット番号を持っている = カットの先頭
            if (cut !== null) {
                // カット位置のマーカーを挿入
                timelineList.push({
                    cut: d.cut,
                    imgpic: "SubHeader",
                    imgcuttime: "SubHeader",
                    active: true,
                    frames: 0,
                });
            }

            // 一時保存のデータがあるときはそのデータを反映させる
            const isActive = d.active !== undefined ? d.active : true;
            timelineList.push({
                cut: null,
                imgpic: d.imgpic,
                imgcuttime: d.imgcuttime,
                active: isActive,
                frames: d.frames,
            });
            if (timeline !== undefined && i + 1 < timeline.length && d.cut) {
                cutBegins.push(timeline[i + 1].imgpic);
                if (i > 1) {
                    cutEnds.push(timeline[i - 1].imgpic);
                }
            }
            if (timeline && timeline.length - 1 === i) {
                cutEnds.push(d.imgpic);
            }
        });
    }

    // シーンリスト用のデータを作成
    cutBegins.forEach((d, i) => {
        if (i === 0) {
            cuts.push({
                scene: 1,
                cut: null,
                begin: "",
                end: "",
                active: true,
            });
        }
        cuts.push({
            scene: null,
            cut: i + 1,
            begin: d,
            end: cutEnds[i],
            active: true,
        });
    });
    // ワークスペースフォームの設定
    const {
        control,
        register,
        handleSubmit,
        setValue,
        // formState: { errors }, // エラー情報 (メッセージなど) を含む`state`
    } = useForm<WorkspaceFormModel>({
        mode: "onChange", // バリデーション判定タイミング (`onChange`は入力値の変化毎)
        defaultValues,
        // resolver: yupResolver(schema),
    });
    // 画像と時間入力のリストを管理するFieldArrayの設定
    const {
        fields: fieldsCut,
        move,
        remove,
        append,
        prepend,
        update,
    } = useFieldArray({
        name: "inter.timeline",
        control,
    });
    // カット開始と最後の画像リストを管理するFieldArrayの設定
    const {
        fields: fieldsScene,
        move: moveScene,
        remove: removeScene,
        append: appendScene,
        // prepend,
        // update: updateScene,
    } = useFieldArray({
        name: "inter.sceneImg",
        control,
    });

    // ワークスペースフォーム送信時の処理
    const onSubmit = async (data: WorkspaceFormModel) => {
        // console.log(data);

        // 確認ダイアログ表示
        const postFlag = await new Promise<boolean>((resolve) => {
            setPostDialogProps({
                resolve: resolve,
            });
        });
        // 確認ダイアログ閉じ
        setPostDialogProps(undefined);
        if (postFlag) {
            setOpenProgressDialog({ open: true });
            if (Object.keys(base64List).length > 0) {
                updateImgElements(base64List);
            }

            // カット位置のマーカーを目印にカット番号の振り直し
            // 連続したマーカーは１つとして処理
            // リストの末尾のマーカーは無視
            let cutCount = 1;
            data.inter.timeline.forEach((d, i) => {
                if (d.imgpic === "SubHeader") {
                    if (
                        i > 0 &&
                        data.inter.timeline[i - 1].imgpic !== "SubHeader"
                    ) {
                        cutCount++;
                    }
                    if (data.inter.timeline.length > i + 1) {
                        data.inter.timeline[i + 1].cut = cutCount;
                    }
                } else {
                    // dataの型がバラバラだとPOSTがエラーになるのでNumberで統一
                    d.frames = Number(d.frames);
                }
            });
            // カット位置のマーカーを削除
            data.inter.timeline = data.inter.timeline.filter(
                (item) => item.imgpic !== "SubHeader"
            );

            // シーンのデータを生成
            const sceneBegins: number[] = [];
            const sceneEnds: number[] = [];
            data.inter.sceneImg.forEach((d, i) => {
                if (d.cut === null) {
                    if (i !== 0 && data.inter.sceneImg[i - 1].cut !== null) {
                        sceneEnds.push(Number(data.inter.sceneImg[i - 1].cut));
                    }
                    if (
                        i + 1 < data.inter.sceneImg.length &&
                        data.inter.sceneImg[i + 1].cut !== null
                    ) {
                        sceneBegins.push(
                            Number(data.inter.sceneImg[i + 1].cut)
                        );
                    }
                }
            });
            // カットの含まないシーンマーカーは無視
            data.inter.sceneImg.filter((item) => item.scene === null);
            sceneEnds.push(
                Number(data.inter.sceneImg[data.inter.sceneImg.length - 1].cut)
            );

            data.inter.scenes = sceneBegins.map((d, i) => ({
                begin: d,
                end: sceneEnds[i],
            }));

            // データ送信
            apiClient
                .post("/api/process/xml", data, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                })
                .then((response) => {
                    // console.log(response);
                    updateWorkspace(data);
                    navigate("/export", {
                        state: { token },
                    });
                })
                .catch((error) => {
                    // console.log(error);
                    setIsPostFailedAlert(true);
                    setOpenProgressDialog({ open: false });
                });
        }
    };

    /**
     * 各ステップで表示するアイテムの配列
     */
    const steps = [
        { label: "設定", item: <InputItems register={register} /> },
        {
            label: "カット",
            item: (
                <TimelineList
                    fields={fieldsCut}
                    setValue={setValue}
                    move={move}
                    remove={remove}
                    update={update}
                    prepend={prepend}
                    append={append}
                    base64List={base64List}
                    register={register}
                />
            ),
        },
        {
            label: "シーン",
            item: (
                <Scene
                    fieldsScene={fieldsScene}
                    fieldsCut={fieldsCut}
                    setValue={setValue}
                    base64List={base64List}
                    move={moveScene}
                    remove={removeScene}
                    append={appendScene}
                />
            ),
        },
    ];
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set<number>());

    const isStepSkipped = (step: number) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return (
        <>
            <Grid
                item
                xs={8}
                minWidth={820}
                sx={{ backgroundColor: "aliceblue" }}
            >
                <Grid container direction="row" justifyContent="center" py={1}>
                    <Grid item xs={6}>
                        <InfoTable responseData={data} />
                    </Grid>
                </Grid>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={4}
                        py={2}
                    >
                        <Grid item xs={10}>
                            <Stepper activeStep={activeStep} alternativeLabel>
                                {steps.map((step, index) => {
                                    const stepProps: { completed?: boolean } =
                                        {};
                                    const labelProps: {
                                        optional?: React.ReactNode;
                                    } = {};
                                    if (isStepSkipped(index)) {
                                        stepProps.completed = false;
                                    }
                                    return (
                                        <Step key={step.label} {...stepProps}>
                                            <StepLabel {...labelProps}>
                                                {step.label}
                                            </StepLabel>
                                        </Step>
                                    );
                                })}
                            </Stepper>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    pt: 2,
                                }}
                            >
                                <Button
                                    color="inherit"
                                    variant="contained"
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    sx={{ borderRadius: 10 }}
                                >
                                    Back
                                </Button>
                                <Box sx={{ flex: "1 1 auto" }} />
                                <Button
                                    variant="contained"
                                    onClick={handleNext}
                                    disabled={activeStep === steps.length}
                                    sx={{
                                        borderRadius: 10,
                                    }}
                                >
                                    {activeStep === steps.length - 1
                                        ? "Finish"
                                        : "Next"}
                                </Button>
                            </Box>
                            {activeStep < steps.length &&
                                steps[activeStep].item}
                            {activeStep === steps.length && (
                                <Box>
                                    <Typography
                                        variant="h4"
                                        sx={{ color: "#384553" }}
                                    >
                                        準備が完了しました！
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        py={2}
                                        sx={{ fontSize: 20, color: "#56626f" }}
                                    >
                                        書き出しボタンを押すとZIPファイルを作成できます。
                                    </Typography>
                                </Box>
                            )}
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container justifyContent="center">
                            <Grid item xs={4}>
                                <PostFailedAlert setAlert={isPostFailedAlert} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} pb={4}>
                        <Button
                            variant="contained"
                            onClick={() => {
                                navigate("/", {
                                    state: { token },
                                });
                            }}
                            size="large"
                            sx={{
                                mx: 1.5,
                                px: 1,
                                width: 140,
                            }}
                            style={{
                                color: "steelblue",
                                backgroundColor: "#e2e9f0",
                            }}
                        >
                            コンテ登録に戻る
                        </Button>
                        {activeStep === steps.length && (
                            <Button
                                variant="contained"
                                type="submit"
                                size="large"
                                sx={{
                                    mx: 2,
                                    width: 120,
                                    backgroundColor: "steelblue",
                                }}
                            >
                                書き出し
                            </Button>
                        )}
                    </Grid>
                </form>
            </Grid>
            {postDialogProps && <WorkspacePostDialog {...postDialogProps} />}
            {openProgressDialog && (
                <WorkspacePostProgressDialog {...openProgressDialog} />
            )}
        </>
    );
}

type InputItemsProps = {
    register: UseFormRegister<WorkspaceFormModel>;
};
/**
 * initialCutNumber, output_file_path の入力欄
 */
function InputItems({ register }: InputItemsProps) {
    return (
        <Grid
            container
            direction="column"
            width={1 / 1}
            px={20}
            py={6}
            alignContent="flex-start"
            spacing={6}
        >
            <Grid item textAlign="left">
                <TextField
                    required
                    type="number"
                    inputProps={{ min: 1 }}
                    label={process.env.REACT_APP_INIRIAL_CUT_NUMBER}
                    defaultValue={
                        process.env.REACT_APP_INIRIAL_CUT_NUMBER_DEFAULT_VALUE
                    }
                    variant="outlined"
                    sx={{ width: 120 }}
                    {...register("inter.initialCutNumber")}
                />
            </Grid>
            <Grid item>
                <TextField
                    required
                    label={process.env.REACT_APP_OUTPUT_FILE_PATH}
                    defaultValue={
                        process.env.REACT_APP_OUTPUT_FILE_PATH_DEFAULT_VALUE
                    }
                    variant="outlined"
                    sx={{ width: 360 }}
                    {...register("output_file_path")}
                />
            </Grid>
        </Grid>
    );
}

type TimelineListProps = {
    fields: FieldArrayWithId<WorkspaceFormModel, "inter.timeline", "id">[];
    setValue: UseFormSetValue<WorkspaceFormModel>;
    move: UseFieldArrayMove;
    remove: UseFieldArrayRemove;
    update: UseFieldArrayUpdate<WorkspaceFormModel, "inter.timeline">;
    prepend: UseFieldArrayPrepend<WorkspaceFormModel, "inter.timeline">;
    append: UseFieldArrayAppend<WorkspaceFormModel, "inter.timeline">;
    base64List: ImgElementsModel;
    register: UseFormRegister<WorkspaceFormModel>;
};
/**
 * 画像と時間の入力欄リスト
 */
function TimelineList({ ...props }: TimelineListProps) {
    const { imgElements } = useContext(WorkspaceImgContext);

    // response のデータがあるときはそっちを優先
    const imgList =
        Object.keys(props.base64List).length > 0
            ? props.base64List
            : imgElements;

    const [isAddCutMarkerAlert, setIsAddCutMarkerAlert] =
        useState<boolean>(false);

    // ドラッグ移動を縦方向のみに制限
    const modifiers = [restrictToVerticalAxis];

    // リストドラッグ後の処理
    const onDragEnd = async (event: DragEndEvent) => {
        // console.log("dragging");
        const { active, over } = event;
        if (!over || active.id === over.id) return;

        const activeIndex = active.data.current?.sortable?.index;
        const overIndex = over.data.current?.sortable?.index;

        if (activeIndex !== undefined && overIndex !== undefined) {
            // リストのデータを移動
            props.move(activeIndex, overIndex);
        }
    };

    // カット位置編集のオンオフを管理
    const [editCutMarkersChecked, setEditCutMarkersChecked] =
        useState<boolean>(false);

    // カット番号追加時の処理
    function addCutMarker() {
        // console.log("add");
        setIsAddCutMarkerAlert(true);
        props.append({
            cut: -1,
            imgpic: "SubHeader",
            imgcuttime: "SubHeader",
            active: true,
            frames: 0,
        });
    }
    function onCloseAddCutMarkerAlert() {
        setIsAddCutMarkerAlert(false);
    }

    let cutCount: number = 0;
    /**
     * リストに表示するカット番号を返す関数
     * @param imgpic フォームデータのimgpic
     * @param cut フォームデータのcut
     * @param i リストのインデックス
     * @returns カットマーカーではないなら null, 新しく追加されたマーカーなら -1, 既存のマーカーならカット番号
     */
    function cutNumber(imgpic: string, cut: number | null, i: number) {
        if (cut === -1) {
            props.setValue(`inter.timeline.${i}.cut`, 0);
            return -1;
        }
        if (imgpic === "SubHeader") {
            cutCount++;
            return cutCount;
        }
        return null;
    }

    /**
     * コマの削除ボタンが押されたときの処理
     * @param id fieldのID
     */
    function inActive(id: number) {
        props.update(id, {
            ...props.fields[id],
            active: !props.fields[id].active,
        });
    }
    return (
        <>
            <AddCutMarkerAlert
                setAlert={isAddCutMarkerAlert}
                onClose={onCloseAddCutMarkerAlert}
            />
            <ControlButtons
                editMarkersChecked={editCutMarkersChecked}
                setEditMarkersChecked={setEditCutMarkersChecked}
                addMarker={addCutMarker}
                switchText="カット位置を編集"
                addBtnText="カット番号を追加する"
            />
            <DndContext modifiers={modifiers} onDragEnd={onDragEnd}>
                <List
                    sx={{
                        position: "relative",
                        overflow: "auto",
                        maxHeight: 500,
                        p: 0,
                        "& ul": { padding: 0 },
                    }}
                >
                    <SortableContext items={props.fields}>
                        {props.fields.map((field, index) => (
                            <MyListItem
                                key={field.id}
                                field={field}
                                remove={props.remove}
                                inActive={inActive}
                                index={index}
                                editMarkersChecked={editCutMarkersChecked}
                                markerNumber={cutNumber(
                                    props.fields[index].imgpic,
                                    props.fields[index].cut,
                                    index
                                )}
                                isMarker={field.imgpic === "SubHeader"}
                                markerText="cut"
                            >
                                <CutListItem
                                    field={field}
                                    index={index}
                                    imgList={imgList}
                                    register={props.register}
                                />
                            </MyListItem>
                        ))}
                    </SortableContext>
                </List>
            </DndContext>
        </>
    );
}
type SceneProps = {
    fieldsScene: FieldArrayWithId<WorkspaceFormModel, "inter.sceneImg", "id">[];
    fieldsCut: FieldArrayWithId<WorkspaceFormModel, "inter.timeline", "id">[];
    setValue: UseFormSetValue<WorkspaceFormModel>;
    move: UseFieldArrayMove;
    remove: UseFieldArrayRemove;
    append: UseFieldArrayAppend<WorkspaceFormModel, "inter.sceneImg">;
    base64List: ImgElementsModel;
};
/**
 * シーン位置を編集するリスト
 */
function Scene({
    fieldsScene,
    fieldsCut,
    setValue,
    move,
    remove,
    append,
    base64List,
}: SceneProps) {
    const { imgElements } = useContext(WorkspaceImgContext);

    // response のデータがあるときはそっちを優先
    const imgList =
        Object.keys(base64List).length > 0 ? base64List : imgElements;

    const [isAddSceneMarkerAlert, setIsAddSceneMarkerAlert] =
        useState<boolean>(false);

    // ドラッグ移動を縦方向のみに制限
    const modifiers = [restrictToVerticalAxis];

    // リストドラッグ後の処理
    const onDragEnd = async (event: DragEndEvent) => {
        // console.log("dragging");
        const { active, over } = event;
        if (!over || active.id === over.id) return;

        const activeIndex = active.data.current?.sortable?.index;
        const overIndex = over.data.current?.sortable?.index;

        if (activeIndex !== undefined && overIndex !== undefined) {
            // リストのデータを移動
            move(activeIndex, overIndex);
        }
    };

    // Timelineリストをもとに管理用データを作成
    useEffect(() => {
        const cuts: CutObj[] = [];
        const cutBegins: Array<string> = [];
        const cutEnds: Array<string> = [];
        const markers: Array<number> = [];
        fieldsScene.forEach((d, i) => {
            if (i > 0 && i + 1 < fieldsScene.length && d.cut === null) {
                // マーカー位置を記録する
                markers.push(i);
            }
        });
        // カット最初と最後のコマのリストを作成
        // 無効中のカットは含まない
        fieldsCut.forEach((d, i) => {
            if (d.imgpic === "SubHeader") {
                for (let index = i + 1; index < fieldsCut.length; index++) {
                    if (fieldsCut[index].active === true) {
                        cutBegins.push(fieldsCut[index].imgpic);
                        break;
                    }
                    if (index === fieldsCut.length - 1) {
                        cutBegins.push("SubHeader");
                    }
                }
                if (i !== 0) {
                    for (let index = i - 1; index >= 0; index--) {
                        if (fieldsCut[index].active === true) {
                            cutEnds.push(fieldsCut[index].imgpic);
                            break;
                        }
                        if (index === 0) {
                            cutEnds.push("SubHeader");
                        }
                    }
                }
            }
            if (fieldsCut.length - 1 === i) {
                for (let index = i; index >= 0; index--) {
                    if (fieldsCut[index].active === true) {
                        cutEnds.push(fieldsCut[index].imgpic);
                        break;
                    }
                    if (index === 0) {
                        cutEnds.push("SubHeader");
                    }
                }
            }
        });

        // シーンマーカーの挿入
        let markerCount = 0;
        let cutCount = 1;
        cutBegins.forEach((d, i) => {
            if (i === 0 || markers.includes(i + markerCount)) {
                markerCount++;
                cuts.push({
                    scene: markerCount,
                    cut: null,
                    begin: "",
                    end: "",
                    active: true,
                });
            }
            cuts.push({
                scene: null,
                cut: cutCount,
                begin: d,
                end: cutEnds[i],
                active: true,
            });
            cutCount++;
        });
        setValue(`inter.sceneImg`, cuts);
    }, []);

    // シーン位置編集のオンオフを管理
    const [editSceneMarkersChecked, setEditSceneMarkersChecked] =
        useState<boolean>(false);

    // シーン番号追加時の処理
    function addSceneMarker() {
        // console.log("add");
        append({
            scene: -1,
            cut: null,
            begin: "",
            end: "",
            active: true,
        });
        setIsAddSceneMarkerAlert(true);
    }
    function onCloseAddSceneMarkerAlert() {
        setIsAddSceneMarkerAlert(false);
    }

    let sceneCount: number = 0;
    /**
     * リストに表示するシーン番号を返す関数
     * @param scene フォームデータのscene
     * @param i リストのインデックス
     * @returns カットマーカーではないなら null, 新しく追加されたマーカーなら -1, 既存のマーカーならカット番号
     */
    function sceneNumber(scene: number | null, i: number) {
        if (scene === -1) {
            setValue(`inter.sceneImg.${i}.scene`, 0);
            return -1;
        }
        if (scene !== null) {
            sceneCount++;
            return sceneCount;
        }
        return null;
    }

    function inActive(id: number) {
        // console.log("inActive : ", id);
        // props.update(id, {
        //     ...props.fields[id],
        //     active: !props.fields[id].active,
        // });
    }
    return (
        <>
            <AddSceneMarkerAlert
                setAlert={isAddSceneMarkerAlert}
                onClose={onCloseAddSceneMarkerAlert}
            />
            <ControlButtons
                editMarkersChecked={editSceneMarkersChecked}
                setEditMarkersChecked={setEditSceneMarkersChecked}
                addMarker={addSceneMarker}
                switchText="シーン位置を編集"
                addBtnText="シーンを追加"
            />
            <DndContext modifiers={modifiers} onDragEnd={onDragEnd}>
                <List
                    sx={{
                        position: "relative",
                        overflow: "auto",
                        maxHeight: 620,
                        p: 0,
                        "& ul": { padding: 0 },
                    }}
                >
                    <SortableContext items={fieldsScene}>
                        <Divider variant="middle" />
                        {fieldsScene.map((field, i) => (
                            <MyListItem
                                key={field.id}
                                field={field}
                                remove={remove}
                                inActive={inActive}
                                index={i}
                                // editMarkersChecked={editSceneMarkersChecked}
                                editMarkersChecked={true}
                                markerNumber={sceneNumber(field.scene, i)}
                                isMarker={field.scene !== null}
                                markerText="scene"
                            >
                                <SceneListItem data={field} imgList={imgList} />
                            </MyListItem>
                        ))}
                    </SortableContext>
                </List>
            </DndContext>
        </>
    );
}
