import { useNavigate } from "react-router-dom";

import { IconButton, Tooltip } from "@mui/material";
import { HelpRounded } from "@mui/icons-material";

export function Manual() {
    const navigate = useNavigate();
    function manual() {
        console.log("manual");
        window.open("https://livret.little-bit.jp:4443/manual/index.html", "_blank");
        // navigate("/manual");
    }
    return (
        <footer
            style={{
                position: "fixed",
                left: 0,
                bottom: 0,
                width: "100%",
                textAlign: "right",
            }}
        >
            <Tooltip title="マニュアルを開く">
                <IconButton aria-label="delete" size="large" onClick={manual}>
                    <HelpRounded fontSize="large" color="disabled" />
                </IconButton>
            </Tooltip>
        </footer>
    );
}
