import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    LinearProgress,
} from "@mui/material";

/**
 * @property open : trueなら表示falseなら非表示
 */
export type ProgressDialogProps = {
    open: boolean;
};
/**
 * ファイルフォームの送信中ダイアログ
 * @param open trueなら表示falseなら非表示
 */
export function FilePostProgressDialog({ open }: ProgressDialogProps) {
    return (
        <ProgressDialog
            open={open}
            title="画像を処理中です。"
            text="しばらくお待ちください。"
        />
    );
}
/**
 * ワークスペースフォームの送信中ダイアログ
 * @param open trueなら表示falseなら非表示
 */
export function WorkspacePostProgressDialog({ open }: ProgressDialogProps) {
    return (
        <ProgressDialog
            open={open}
            title="ZIPファイルを作成中です。"
            text="しばらくお待ちください。"
        />
    );
}
type ProgressDialogItemProps = {
    open: boolean;
    title: string;
    text: string;
};
/**
 * 処理中、実行中であることを知らせるダイアログ、プログレスバー付き
 * @param open trueなら表示falseなら非表示
 * @param title ダイアログのタイトルテキスト
 * @param text ダイアログの本文テキスト
 */
function ProgressDialog(props: ProgressDialogItemProps) {
    return (
        <Dialog
            open={props.open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <LinearProgress />
            <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.text}
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );
}
