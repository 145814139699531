import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import {
    Grid,
    Box,
    Typography,
    Button,
    IconButton,
    FormControl,
    InputLabel,
    FilledInput,
    InputAdornment,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import apiClient from "apiClient";
import { LoginFormModel } from "model/formModel";
import { LoginFailedAlert } from "parts/Alert";

export function LoginForm() {
    const navigate = useNavigate();

    const [showPassword, setShowPassword] = useState(false);
    const [isLoginFailedAlert, setIsLoginFailedAlert] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors }, // エラー情報 (メッセージなど) を含む`state`
    } = useForm<LoginFormModel>({
        mode: "onChange", // バリデーション判定タイミング (`onChange`は入力値の変化毎)
        // resolver: yupResolver(schema),
    });

    const onSubmit = async (data: LoginFormModel) => {
        // console.log(data);

        apiClient
            .post("/api/token", data)
            .then((response) => {
                // console.log(response);
                const token = response.data.token;
                navigate("/", {
                    state: { token },
                });
            })
            .catch((error) => {
                // console.log(error);
                setIsLoginFailedAlert(true);
            });
    };

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
    };
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
                rowSpacing={4}
                p={10}
                pt={4}
                sx={{ backgroundColor: "aliceblue" }}
            >
                <Box>
                    <Typography
                        variant="h2"
                        gutterBottom
                        sx={{ color: "coral" }}
                    >
                        livret
                    </Typography>
                </Box>
                <Grid item>
                    <LoginFailedAlert setAlert={isLoginFailedAlert} />
                </Grid>
                <Grid item>
                    <FormControl variant="filled">
                        <InputLabel htmlFor="email">
                            {process.env.REACT_APP_EMAIL}
                        </InputLabel>
                        <FilledInput
                            required
                            id="email"
                            type="email"
                            {...register("email")}
                            sx={{ width: 380 }}
                        />
                    </FormControl>
                </Grid>
                <Grid item>
                    <FormControl variant="filled">
                        <InputLabel htmlFor="password">
                            {process.env.REACT_APP_PASSWORD}
                        </InputLabel>
                        <FilledInput
                            id="password"
                            type={showPassword ? "text" : "password"}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? (
                                            <VisibilityOff />
                                        ) : (
                                            <Visibility />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            }
                            {...register("password")}
                            sx={{ width: 380 }}
                        />
                    </FormControl>
                </Grid>

                <Grid item>
                    <Button
                        variant="contained"
                        type="submit"
                        size="large"
                        sx={{
                            width: 120,
                            backgroundColor: "steelblue",
                        }}
                    >
                        ログイン
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
}
