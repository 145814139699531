import { Routes, Route } from "react-router-dom";
import Top from "pages/Top";
import NotFound from "pages/NotFound";
import Login from "pages/Login";
import Workspace from "pages/Workspace";
import Expost from "pages/Export";

export default function MyRoutes() {
    return (
        <Routes>
            <Route path="*" element={<NotFound />} />
            <Route path="/" element={<Top />} />
            <Route path="/login" element={<Login />} />
            <Route path="/workspace" element={<Workspace />} />
            <Route path="/export" element={<Expost />} />
        </Routes>
    );
}
