import { Alert } from "@mui/material";

type FailedAlertProps = {
    setAlert: boolean;
};
/**
 * ログインの失敗を知らせるアラート
 * @param setAlert trueなら表示falseなら非表示
 */
export function LoginFailedAlert({ setAlert }: FailedAlertProps) {
    return <FailedAlert setAlert={setAlert} text="ログインに失敗しました。" />;
}
/**
 * 画像のアップロードの失敗を知らせるアラート
 * @param setAlert trueなら表示falseなら非表示
 */
export function ImgUploadFailedAlert({ setAlert }: FailedAlertProps) {
    return (
        <FailedAlert
            setAlert={setAlert}
            text="ファイルの登録に失敗しました。"
        />
    );
}
/**
 * コンテ画像が1枚も選択されていないことを知らせるアラート
 * @param setAlert trueなら表示falseなら非表示
 */
export function UnAddedAlert({ setAlert }: FailedAlertProps) {
    return (
        <FailedAlert
            setAlert={setAlert}
            text="コンテ画像が選択されていません。"
        />
    );
}
/**
 * フォーム送信の失敗を知らせるアラート
 * @param setAlert trueなら表示falseなら非表示
 */
export function PostFailedAlert({ setAlert }: FailedAlertProps) {
    return (
        <FailedAlert
            setAlert={setAlert}
            text="フォームの送信に失敗しました。"
        />
    );
}
/**
 * ダウンロードファイル作成の失敗を知らせるアラート
 * @param setAlert trueなら表示falseなら非表示
 */
export function DownloadFailedAlert({ setAlert }: FailedAlertProps) {
    return (
        <FailedAlert
            setAlert={setAlert}
            text={`ZIPファイルの\n作成に\n失敗しました。`}
        />
    );
}

type FailedAlertItemProps = {
    setAlert: boolean;
    text: string;
};
/**
 * 失敗を知らせるアラート
 * @param setAlert trueなら表示falseなら非表示
 * @param text アラートのテキスト 改行にも対応
 * @example
 *      <FailedAlert
 *          setAlert={ture}
 *          text={`この\nアラートは\n改行可。`}
 *      />
 */
function FailedAlert({ setAlert, text }: FailedAlertItemProps) {
    return setAlert ? (
        <Alert
            variant="standard"
            severity="error"
            sx={{ whiteSpace: "pre-line" }}
        >
            {text}
        </Alert>
    ) : (
        <></>
    );
}

type AddMarkerAlertProps = {
    setAlert: boolean;
    onClose: () => void;
};
/**
 * カットマーカーが追加されたことを知らせるアラート
 * @param setAlert trueなら表示falseなら非表示
 * @param onClose ダイアログを閉じる関数
 */
export function AddCutMarkerAlert({ setAlert, onClose }: AddMarkerAlertProps) {
    return (
        <AddMarkerAlert
            setAlert={setAlert}
            text={`カット番号はリストの一番下に追加されました。`}
            onClose={onClose}
        />
    );
}
/**
 * シーンマーカーが追加されたことを知らせるアラート
 * @param setAlert trueなら表示falseなら非表示
 * @param onClose ダイアログを閉じる関数
 */
export function AddSceneMarkerAlert({
    setAlert,
    onClose,
}: AddMarkerAlertProps) {
    return (
        <AddMarkerAlert
            setAlert={setAlert}
            text={`シーン番号はリストの一番下に追加されました。`}
            onClose={onClose}
        />
    );
}
type AddMarkerAlertItemProps = {
    setAlert: boolean;
    text: string;
    onClose: () => void;
};
/**
 * マーカーが追加されたことを知らせるアラート
 * @param setAlert trueなら表示falseなら非表示
 * @param text アラートのテキスト
 * @param onClose ダイアログを閉じる関数
 * @example
 *  const [isAlert, setIsAlert] = useState<boolean>(false);
    function onCloseAlert() {setIsAlert(false);}
 *  return (
 *      <AddMarkerAlert
 *          setAlert={isAlert}
 *          text="追加されました"
 *          onClose={onCloseAlert}
 *      />);
 */
function AddMarkerAlert({ setAlert, text, onClose }: AddMarkerAlertItemProps) {
    return setAlert ? (
        <Alert
            variant="standard"
            severity="success"
            onClose={onClose}
            sx={{ whiteSpace: "pre-line", mx: 12 }}
        >
            {text}
        </Alert>
    ) : (
        <></>
    );
}
